
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "8.2.1 (7f468618131a27bf996519d3e0f94f8a)",
    buildSeed: 1725606196473,
    "wdosbox-x.wasm": {
        "size": 6305055,
        "gzSize": 2294421
    },
    "wdosbox-x.js": {
        "size": 230427,
        "gzSize": 54624
    },
    "wdosbox.wasm": {
        "size": 1467793,
        "gzSize": 499747
    },
    "wdosbox.js": {
        "size": 108170,
        "gzSize": 28965
    },
    "wlibzip.wasm": {
        "size": 112416,
        "gzSize": 52241
    },
    "wlibzip.js": {
        "size": 74095,
        "gzSize": 19630
    }
,
};
